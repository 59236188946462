<template>
  <div></div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { SHARED } from '@/constants/constants'
import {
  MEMBER_GET_SHORTCUT_LINK
} from '@/store/member.module'
export default {
  name: 'shortcut',
  created() {
    this.getLinkFromGuid()
  },
  computed: {
    apiLinkReady() {
      return this.$store.state.member.getShortcutResult
    }
  },
  watch: {
    apiLinkReady(newShortcut, oldShortcut) {
      if (newShortcut.payload) {
        window.location = newShortcut.payload
      } else {
        this.$router.replace({
          name: ROUTE_NAME.HOME
        })
      }
    }
  },
  methods: {
    getLinkFromGuid() {
      let guid = this.$route.query.q
      if (guid) {
        this.$store.dispatch(`${MEMBER_GET_SHORTCUT_LINK}`, { guid })
      } else {
        this.$router.replace({
          name: ROUTE_NAME.HOME
        })
      }
    }
  }
}
</script>
